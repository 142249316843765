import React, { ReactElement, useState } from 'react'
import { SetStateAction } from 'react'
import { DELETE_KEY_CODES } from '../../lib/cs'

import "./BasicStat.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  value?: number
  onChange?: () => void
  save?: (value: number) => void
}

const BasicStat = ({ value = 0, ...props }: Props): ReactElement => {
  const { name } = props

  const [_value, setValue] = useState(value)

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, setState: (value: SetStateAction<number>) => void) => {

    const onValueChange = props.onChange === undefined ? () => { } : props.onChange
    const save = props.save === undefined ? (i:number) => { } : props.save

    if (e.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    var handled = false;
    if (e.key !== undefined) {
      const value = e.key

      // on delete or space, reset value
      if (DELETE_KEY_CODES.includes(value)) {
        setValue(0)
        onValueChange()
        save(0)
        return
      }

      const intValue = parseInt(e.key)
      if (isFinite(intValue)) {
        setValue(intValue)
        onValueChange()
        save(intValue)
      }
    }

    if (handled) {
      // Suppress "double action" if event handled
      e.preventDefault();
    }
  }

  return (
    <div className={props.className}>
      <input name={name}
        type="text"
        value={_value === 0 ? "" : _value}
        onChange={() => { }}
        onKeyUp={(e) => onKeyUp(e, setValue)}
        className="cs cs-stat stat-basic" />
    </div>
  )
}

export default BasicStat
