import store from '.';
import api from '../lib/api';
import { PlayableSound } from '../lib/audio_file';
import { setDirty, setSaveActive } from './reducers/soundsSlice';

const serializeAudioStateToLibrary = () => {
  const sounds = new Array<PlayableSound>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [url, item] of Object.entries(store.getState().audio.files)) {
    sounds.push({
      ...item.metadata,
      ...item.soundState
    })
  }

  return {
    sounds: sounds
  }
}

/**
 * serializes the audio state to a JSON libary and calls API to save
 * @returns 
 */
export const saveLibraryToAPI = () => {

  const { dirty, saveActive, libraryCode } = store.getState().sounds

  if (saveActive === false && dirty === true) {
    store.dispatch(setDirty(false))
    store.dispatch(setSaveActive(true))
    api.saveSoundLibrary(libraryCode, serializeAudioStateToLibrary())
      .catch((error) => console.error(error))
      .finally(() => {
        store.dispatch(setSaveActive(false))
      })
  }
}