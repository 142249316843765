import { faFileAudio } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

import './FileDropzone.scss'

interface FileDropzoneProps {
  addFile: (file: File) => void
  uploadActive: boolean
  uploadProgress: number
}

function FileDropzone(props: FileDropzoneProps) {

  const { addFile } = props
  const disabled = props.uploadActive === undefined ? false : props.uploadActive

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      for (var i = 0; i < acceptedFiles.length; i++) {
        addFile(acceptedFiles[i])
      }
    }
  }, [addFile])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: disabled })

  return (
    <>
      <ProgressBar variant="warning" className={`sounds sound-dropzone sound-dropzone-progressbar ${props.uploadActive ? "" : "d-none"}`} now={props.uploadProgress} animated />
      <div className={`sounds sound-dropzone sound-dropzone-container ${props.uploadActive ? "d-none" : ""}`}>

        <div className="sounds sound-dropzone sound-dropzone-zone">
          <div className={props.uploadActive ? "d-none" : ""}>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : "inactive"}`}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <h4>
                    <FontAwesomeIcon icon={faFileAudio} /> Drop audio files here
                  </h4> :
                  <h4>Drag audio files here, or click to select files (.ogg, .wav, .mp3)</h4>
              }
            </div>
          </div>


        </div>
      </div>
    </>
  );

}

export default FileDropzone;
