import React from 'react';
import { Container } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import ReactGA from 'react-ga4';
import { RootState } from './store';
import Home from './components/Home';
import SoundLibraryContainer from './components/SoundLibraryContainer';
import Navigation from './components/Navigation';
import NewSoundLibraryContainer from './components/NewSoundLibraryContainer';
import CharacterSheets from './components/CharacterSheets'

ReactGA.initialize("UA-163117873-1");

const mapStateToProps = (state: RootState) => ({
  saveActive: state.sounds.saveActive,
  loadActive: state.sounds.loadActive,
  isEditable: state.sounds.isEditable,
  libraryCode: state.sounds.libraryCode
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

function App(props: PropsFromRedux) {

  const appHistory = createBrowserHistory();

  // Initialize google analytics page view tracking
  appHistory.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
  });


  return (
    <Router>

      <Navigation saveActive={props.saveActive}
        loadActive={props.loadActive}
        libraryCode={props.libraryCode} />

      <Container fluid>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/library/audio/new" component={NewSoundLibraryContainer} />
          <Route exact path="/library/audio/:libraryCode">
            <SoundLibraryContainer
              saveActive={props.saveActive}
              loadActive={props.loadActive}
              isEditable={props.isEditable} />
          </Route>
          <Route exact path="/cs" component={CharacterSheets}  />
        </Switch>

      </Container>
    </Router>
  );
}

export default connector(App);
