import React, { ReactElement, useState } from 'react'

import "./HealthBar.scss"
import HeartCheckbox from './HeartCheckbox'

const MAX_HEARTS = 5

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: number
  onChange?: () => void
  save?: (hearts: number) => void
}

const HealthBar = ({ value = 1, ...props }: Props): ReactElement => {

  const [_value, setValue] = useState(value)

  const onChecked = (position: number, checked: boolean) => {
    let newValue = -1

    // alow the first heart to be toggled off
    if (position === 1 && checked === false && _value === 1) {
      newValue = -1
    } else {
      newValue = position
    }

    setValue(newValue)

    if (props.onChange !== undefined) {
      props.onChange()
    }
    if (props.save !== undefined) {
      props.save(newValue)
    }
  }

  return (
    <div className={`${props.className} cs-core-stats-healthbar`}>
      {Array.from(Array(MAX_HEARTS).keys()).map(i => <HeartCheckbox key={i} checked={_value > i} onChecked={(checked) => onChecked(i + 1, checked)} />)}
    </div>
  )
}

export default HealthBar
