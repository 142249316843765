import axios from "axios"
import { PlayableSound } from "./audio_file"

export interface SoundLibraryResponse {
  sounds: PlayableSound[]
}

export interface SaveSoundLibraryRequest {
  sounds: PlayableSound[]
}

export interface SuccessMessageResponse {
  message: string
}

export interface NewSoundResponseSuccess {
  url: string;
}

export interface NewSoundResponseError {
  message: string;
}


export interface SerializedAudioState {
  sounds: PlayableSound[]
}


export type OnUploadProgress = (progressEvent: ProgressEvent) => void

export const API_ENDPOINT = "https://rpgparty.herokuapp.com"
// export const API_ENDPOINT = "http://localhost:8000"

const fileUploadFormField = "audio"

const getLibraryCodePath = (libraryCode: string): string => {
  return `/library/audio/${libraryCode}`
}

const getLibraryCodeURL = (libraryCode: string): string => {
  return `${window.location.protocol}//${window.location.host}${getLibraryCodePath(libraryCode)}`
}

const getSoundLibraryFromCode = async (libraryCode: string): Promise<SoundLibraryResponse> => {
  return await new Promise<SoundLibraryResponse>((resolve, reject) => {

    axios.get<SoundLibraryResponse>(`${API_ENDPOINT}/library/audio/${libraryCode}`)
      .then((response) => {
        resolve(response.data as SoundLibraryResponse)
      })
      .catch((error) => reject(error))
  })
}

const createNewSoundLibrary = async (libraryCode: string): Promise<SoundLibraryResponse> => {
  return await new Promise<SoundLibraryResponse>((resolve, reject) => {

    axios.post<SoundLibraryResponse>(`${API_ENDPOINT}/library/audio/new/${libraryCode}`)
      .then((response) => {
        resolve(response.data as SoundLibraryResponse)
      })
      .catch((error) => reject(error))
  })
}

const saveSoundLibrary = async (libraryCode: string, data: SaveSoundLibraryRequest): Promise<SuccessMessageResponse> => {
  return await new Promise<SuccessMessageResponse>((resolve, reject) => {

    axios.post<SuccessMessageResponse>(`${API_ENDPOINT}/library/audio/save/${libraryCode}`, data)
      .then((response) => {
        resolve(response.data as SuccessMessageResponse)
      })
      .catch((error) => reject(error))
  })
}

const uploadFile = async (libraryCode: string, file: File, onProgress: OnUploadProgress): Promise<NewSoundResponseSuccess> => {
  return await new Promise<NewSoundResponseSuccess>((resolve, reject) => {
    const formData = new FormData();
    formData.append(fileUploadFormField, file, file.name);

    const config = {
      onUploadProgress: onProgress,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    axios.post<NewSoundResponseSuccess>(`${API_ENDPOINT}/upload/${libraryCode}`, formData, config)
      .then((resp: any) => resolve(resp.data as NewSoundResponseSuccess))
      .catch((error: any) => reject(error as NewSoundResponseError))

  })
}

const loadAudioURL = async (url: string): Promise<ArrayBuffer> => {
  return await new Promise<ArrayBuffer>((resolve, reject) => {
    axios.request({
      url: url,
      responseType: "arraybuffer",
    })
      .then((response) => resolve(response.data as ArrayBuffer))
      .catch((error) => reject(`failed to load audio from ${url}: ${error}`))
  })

}

const api = {
  getLibraryCodeURL: getLibraryCodeURL,
  getLibraryCodePath: getLibraryCodePath,
  getSoundLibraryFromCode: getSoundLibraryFromCode,
  createNewSoundLibrary: createNewSoundLibrary,
  saveSoundLibrary: saveSoundLibrary,
  uploadFile: uploadFile,
  loadAudioURL: loadAudioURL,
}

export default api