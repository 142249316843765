import React from 'react'
import { useState } from 'react'
import { PlayerCapability } from '../../lib/cs'

import "./CapabilityItem.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  index: number
  type: string
  value: PlayerCapability
  onChange?: () => void
  save?: (capability: PlayerCapability) => void
}

const CapabilityItem = (props: Props) => {

  const [_capability, setCapability] = useState(props.value)

  const save = (newValue: PlayerCapability) => {
    setCapability(newValue)
    if (props.save !== undefined) {
      props.save(newValue)
    }
    if (props.onChange !== undefined) {
      props.onChange()
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    save({
      ..._capability,
      name: e.currentTarget.value
    })
  }

  return (
    <div className={`cs capability-table capability-row ${props.type}`}>
      <div className="cs capability-table capability-row capability-row-name">
        <textarea spellCheck="false" name={`capability-item-${props.index}`} value={_capability.name} onChange={onChange} placeholder="" />
      </div>
    </div>
  )
}

export default CapabilityItem
