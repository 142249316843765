import React from 'react'
import { useState } from 'react'
import { PlayerCapability } from '../../lib/cs'
import CapabilityItem from './CapabilityItem'

import "./CapabilityTable.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  value: PlayerCapability[]
  onChange?: () => void
  save?: (capability: PlayerCapability[]) => void
}

const CapabilityTable = (props: Props) => {

  const [_table, setTable] = useState(props.value)

  const onChange = props.onChange === undefined ? () => { } : props.onChange

  const save = (capability: PlayerCapability) => {

    // remove the capability from the table
    const newValue = _table.filter(item => item.index !== capability.index)

    // add the new item
    newValue.push(capability)

    // re-sort by index value
    newValue.sort((a,b) => a.index - b.index)

    setTable(newValue)

    if (props.save !== undefined) {
      props.save(newValue)
    }

    if (props.onChange !== undefined) {
      props.onChange()
    }
  }

  return (
    <div className={props.className}>
      <div className={`cs capability-table capability-table-container ${props.type}`}>
        {_table.map((capability, index) => {
          return (
            <CapabilityItem
              index={index}
              key={index}
              type={props.type}
              value={capability}
              save={save}
              onChange={onChange} />
          )
        })}
      </div>
    </div>
  )
}

export default CapabilityTable
