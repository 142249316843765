import api from "../lib/api"
import { AudioFile, Sample, SoundMetadata, SoundState } from "../lib/audio_file"

/**
 * adds an audio URL to the audio library and loads the file into an audio buffer
 * @param metadata 
 * @param soundState 
 * @returns 
 */
export const addAudioFileFromURL = async (metadata: SoundMetadata, soundState: SoundState): Promise<AudioFile> => {

  return await new Promise<AudioFile>((resolve, reject) => {

    api.loadAudioURL(metadata.url)
      .then((buffer) => {

        const samples = metadata.samples === undefined ? new Array<Sample>() : metadata.samples

        const audioFile = new AudioFile(metadata.url, {
          volume: soundState.volume,
          loop: soundState.loop
        }, samples)

        audioFile.SetBuffer(buffer)

        resolve(audioFile)

      })
      .catch((error) => reject(`failed to load audio file ${metadata.url} ${error}`))
  })
}
