import path from 'path';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import ReactGA from 'react-ga4'
import { connect, ConnectedProps } from 'react-redux';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDiceD20, faStop } from '@fortawesome/free-solid-svg-icons';
import { AudioType, DEFAULT_MEDIA_STATE, PlayableSound } from '../lib/audio_file'
import { setEditable, setLoadActive, setLibraryCode, setDirty } from '../store/reducers/soundsSlice'
import { stopAll, setPlaybackEnabled, addAudioFile, AudioItem, setIsReady, setIsLoading, setErrorMessage } from '../store/reducers/audioSlice'
import { useParams } from 'react-router';
import { getLoadingMessage } from '../lib/game';
import { copyToClipboard } from '../lib/util';
import api, { NewSoundResponseError, NewSoundResponseSuccess } from '../lib/api';
import FileDropzone from './library/FileDropzone';
import SoundCategory from './library/SoundCategory';
import { addAudioFileFromURL } from '../store/actions';
import { saveLibraryToAPI } from '../store/library';
import { RootState } from '../store'

import './SoundLibraryContainer.scss'

const saveLibraryDebounceMs = 2000
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const mapStateToProps = (state: RootState) => ({
  isDirty: state.sounds.dirty,
  audioLibrary: state.audio.files
})

const mapDispatchToProps = {
  addAudioFile,
  setLoadActive,
  setEditable,
  setPlaybackEnabled,
  stopAll,
  setLibraryCode,
  setDirty
}

type PropsFromRedux = ConnectedProps<typeof connector>

interface SoundLibraryContainerProps extends PropsFromRedux {
  saveActive: boolean
  loadActive: boolean
  isEditable: boolean
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export interface SoundLibraryContainerParams {
  libraryCode: string
}

const trackLibraryEvent = (action: string) => {
  ReactGA.event({
    category: "library",
    action: action,
  });
}

function SoundLibraryContainer(props: SoundLibraryContainerProps) {

  const { libraryCode } = useParams<SoundLibraryContainerParams>();
  const { setLoadActive, addAudioFile, isDirty, setDirty, setLibraryCode } = props

  const [isLibraryLoading, setIsLibraryLoading] = useState(true)
  const [uploadActive, setUploadActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0)

  const libraryLength = Object.keys(props.audioLibrary).length

  const addPlayableSoundToLibrary = useCallback((sound: PlayableSound) => {
    const metadata = {
      title: sound.title,
      description: sound.description,
      audioType: sound.audioType,
      url: sound.url,
      samples: sound.samples
    }

    const soundState = {
      volume: sound.volume,
      loop: sound.loop
    }

    setIsLoading({
      url: metadata.url,
      isLoading: true,
    })

    // load each audio file
    addAudioFileFromURL(metadata, soundState)
      .then((audioFile) => {

        addAudioFile({
          audioFile: audioFile,
          metadata: metadata,
          soundState: soundState
        })

        setIsReady({
          url: metadata.url
        })

      })
      .catch((error) => {

        console.error(error)
        setErrorMessage({
          url: metadata.url,
          errorMessage: error
        })
      })
      .finally(() => {
        setIsLoading({
          url: metadata.url,
          isLoading: true,
        })
      })
  }, [addAudioFile])

  const addPlayableSoundByURLToLibrary = useCallback((file: File, url: string) => {
    const name = file.name
    const extname = path.extname(name)

    addPlayableSoundToLibrary({
      title: path.basename(name, extname),
      description: name,
      audioType: AudioType.Generic,
      url: url,
      samples: [],
      volume: DEFAULT_MEDIA_STATE.volume,
      loop: DEFAULT_MEDIA_STATE.loop
    })
  }, [addPlayableSoundToLibrary])

  const loadSoundLibrary = useCallback((code: string) => {
    setLoadActive(true)

    api.getSoundLibraryFromCode(code)
      .then((response) => {
        setLibraryCode(code)
        response.sounds.forEach((sound) => addPlayableSoundToLibrary(sound))
        setLibraryCode(code)

        // set library code as dimension
        ReactGA.set({
          "libraryCode": code
        })
      })
      .catch((error) => {
        console.error("failed to load library code", code, error)
      })
      .finally(() => {
        setLoadActive(false)
        setIsLibraryLoading(false)
      })

  }, [addPlayableSoundToLibrary, setLibraryCode, setLoadActive])

  // prevent API spam, debounce saving of library API
  const saveLibrary = debounce(() => saveLibraryToAPI(), saveLibraryDebounceMs)

  const onUploadProgress = (e: ProgressEvent) => {
    setUploadProgress((e.loaded / e.total) * 100)
  }

  const uploadFileToLibrary = (file: File) => {
    setUploadActive(true)
    setUploadProgress(0)

    // upload each file in the queue
    api.uploadFile(libraryCode, file, onUploadProgress)
      .then((resp: NewSoundResponseSuccess) => addPlayableSoundByURLToLibrary(file, resp.url))
      .catch((error: NewSoundResponseError) => {
        trackLibraryEvent("upload-fail")
        console.error("error uploading", file.name, error)
      })
      .finally(() => {
        setUploadActive(false)
        setUploadProgress(0)

        trackLibraryEvent("upload-success")

        // let parent know files changed
        setDirty(true)
      })
  }

  const setEditMode = (b: boolean) => {
    if (b === true) {
      props.setEditable(true)
      props.setPlaybackEnabled(false)
      trackLibraryEvent("edit-enabled")
    } else {
      props.setEditable(false)
      props.setPlaybackEnabled(true)
      trackLibraryEvent("edit-disabled")
    }

  }

  const filterItemsByType = (audioType: AudioType): AudioItem[] => {
    const items = new Array<AudioItem>()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(props.audioLibrary).filter(([url, item]) => item.metadata.audioType === audioType).forEach(([url, item]) => {
      items.push(item)
    })

    return items
  }

  // load all sound files for a library
  useEffect(() => {
    loadSoundLibrary(libraryCode)
  }, [libraryCode, loadSoundLibrary])

  // save library if marked as dirty
  useEffect(() => {
    if (isDirty === true) {
      saveLibrary()
    }
  }, [isDirty, saveLibrary])


  const ToggleEditable = ({ checked = false }) => {
    return (
      <div className="form-check form-switch" aria-details="Toogle edit mode" title="Toogle edit mode">
        <input className="form-check-input" type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => setEditMode(e.currentTarget.checked)} checked={checked} />
      </div>
    )
  }


  const LibraryHeaderText = ({ code = "" }) => {
    return (
      <div>
        Library code for this session: <code
          onClick={() => copyToClipboard(code)}
          title="Click to copy library code"
        >{code}</code> <FontAwesomeIcon icon={faCopy} />

        <span className="px-2">This session has been <a href={api.getLibraryCodeURL(code)}>saved</a>, you can come back to it later.</span>
      </div>
    )
  }

  const StopPlaybackButton = ({ display = true, disabled = false }) => {
    const stopWrapper = () => {
      trackLibraryEvent("stop-playback")
      props.stopAll()
    }
    return (
      <Button className={`stop-all ${!display ? "d-none" : ""}`} size="sm" variant="danger" disabled={disabled} onClick={stopWrapper}>
        <FontAwesomeIcon icon={faStop} />
        &nbsp; <span>playback</span>
      </Button>
    )
  }

  if (isLibraryLoading === true) {
    return (
      <div className="sound_library sound_library-loading">
        <p>
          <FontAwesomeIcon icon={faDiceD20} spin={true} />
        </p>
        <p>{getLoadingMessage()}...</p>
      </div>
    )
  }


  return (
    <div className="sound_library sound_library-container">

      <div className="sound_library sound_library-header sound_library-header-container">

        <div className="sound_library sound_library-header sound_library-header-controls">
          <ToggleEditable checked={props.isEditable} />
          <div className={`${props.isEditable ? "d-none" : ""}`}>
            <StopPlaybackButton />
          </div>
        </div>

        <div className={`sound_library sound_library-header sound_library-header-text ${props.isEditable ? "d-none" : ""}`}>
          <LibraryHeaderText code={libraryCode} />
        </div>

        <div className={`sound_library sound_library-header sound_library-header-dropzone ${props.isEditable ? "" : "d-none"}`}>
          <FileDropzone addFile={uploadFileToLibrary} uploadActive={uploadActive} uploadProgress={uploadProgress} />
        </div>

      </div>

      <div className={`sound_library sound_library-safari_warning ${isSafari ? "" : "d-none"}`}>
        <div>
          Safari can't play <code>.ogg</code> files
        </div>
      </div>

      <div className={`sound_library sound_library-no_sounds ${!props.isEditable && libraryLength === 0 ? "" : "d-none"}`}>
        <div>
          <h1>Add Some Sounds</h1>
          <p>Switch to edit mode by using the toggle on the upper left</p>
          <Button onClick={() => setEditMode(true)}>Switch to Edit Mode</Button>
        </div>
      </div>

      <div className={`sound_library sound_library-category-wrapper ${(props.isEditable && libraryLength > 0) || libraryLength > 0 ? "" : "d-none"}`}>
        <DndProvider backend={HTML5Backend}>
          <SoundCategory onChange={() => setDirty(true)} isEditable={props.isEditable} audioType={AudioType.Generic} items={filterItemsByType(AudioType.Generic)} />
          <SoundCategory onChange={() => setDirty(true)} isEditable={props.isEditable} audioType={AudioType.Event} items={filterItemsByType(AudioType.Event)} />
          <SoundCategory onChange={() => setDirty(true)} isEditable={props.isEditable} audioType={AudioType.Background} items={filterItemsByType(AudioType.Background)} />
          <SoundCategory onChange={() => setDirty(true)} isEditable={props.isEditable} audioType={AudioType.Music} items={filterItemsByType(AudioType.Music)} />
          <SoundCategory onChange={() => setDirty(true)} isEditable={props.isEditable} audioType={AudioType.Character} items={filterItemsByType(AudioType.Character)} />
        </DndProvider>
      </div>

    </div >

  );
}

export default connector(SoundLibraryContainer);
