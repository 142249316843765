import React, { ReactElement } from 'react'

import "./HeartCheckbox.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean
  onChecked: (checked: boolean) => void
}

const HeartCheckbox = (props: Props): ReactElement => {

  const { checked, onChecked } = props

  return (
    <div className={props.className}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChecked(e.currentTarget.checked)}
        className="cs cs-char-health cs-char-health-heart" />
    </div>
  )
}

export default HeartCheckbox
