import React, { ChangeEvent, FormEvent, useState } from 'react';
import ReactGA from 'react-ga4'
import { Form, Button, FormControl } from 'react-bootstrap';
import { Redirect } from 'react-router';
import api from '../lib/api';
import { getNewSessionCode } from '../lib/game';
import { LIBRARY_CODE_ALLOWED_CHARS, MIN_LIBRARY_CODE_LENGTH, validateLibraryCode } from '../lib/validate';

import './NewSoundLibraryContainer.scss'

const trackLibraryEvent = (action: string) => {
  ReactGA.event({
    category: "library",
    action: action,
  });
}

function NewSoundLibraryContainer() {

  const [newCode, setNewCode] = useState(getNewSessionCode())
  const [redirectURL, setRedirectURL] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const disabled = newCode.length < MIN_LIBRARY_CODE_LENGTH || submitted || !validateLibraryCode(newCode)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (disabled) {
      return
    }
    setSubmitted(true)

    api.createNewSoundLibrary(newCode)
      .then((response) => {
        trackLibraryEvent("new")
        setRedirectURL(api.getLibraryCodePath(newCode))
      })
      .catch((error) => {
        console.error("Failed to create sound library", error)
      })
      .finally(() => {
        setSubmitted(false)
      })
  }

  if (redirectURL.length > 0) {
    return <Redirect to={redirectURL} />
  }

  return (
    <div className="sound_library sound_library-create container">

      <div className="sound_library sound_library-create form">
        <h2>Create New Audio Library</h2>
        <p>
          An audio library contains a collection of sounds. Libraries have a unique code so you can share with others.
        </p>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Library Code</Form.Label>

            <FormControl
              aria-label="Library Code"
              placeholder="ex: fit-quill-insurance"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCode(e.currentTarget.value)}
              value={newCode} />

            <Form.Text className="text-muted">
              Minumum {MIN_LIBRARY_CODE_LENGTH} characters,
              allowed chars: &nbsp;<code>A-z, 0-9, {LIBRARY_CODE_ALLOWED_CHARS.join(", ")}</code>
            </Form.Text>

          </Form.Group>

          <Button disabled={disabled} type="submit" size="sm" variant="primary" onClick={() => { }}>
            Create
          </Button>
        </Form>
      </div>

    </div >

  );
}

export default NewSoundLibraryContainer;
