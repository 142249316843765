import React, { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash';
import { PlayerCharacterSheet, defaultCharacterSheetData, stringToFilename } from '../../lib/cs'
import { copyToClipboard, isDevelopment } from '../../lib/util';
import CoreStat from './CoreStat'
import CharText from './CharText'
import HealthBar from './HealthBar'
import BasicStat from './BasicStat'
import CharInfo from './CharInfo'
import LootTable from './LootTable'
import CapabilityTable from './CapabilityTable';

import "./CharacterSheet.scss"

/**
 * Properties for CharacterSheet
 */
interface CharacterSheetProps {
  data?: PlayerCharacterSheet
}

const SAVE_ON_CHANGE_DELAY_MS = 2000
const DEFAULT_SCALE = 0.65
const PRINT_SCALE = 0.388

/**
 * An editable ICRPG Master Edition character sheet
 * @param props CharacterSheetProps
 * @returns 
 */
export const CharacterSheet = (props: CharacterSheetProps) => {

  // populate character sheet data with defaults, regardless of what is set
  const [_data, setData] = useState(props.data === undefined ? defaultCharacterSheetData() : {
    ...defaultCharacterSheetData(),
    ...props.data
  })

  const [scale, setScale] = useState(DEFAULT_SCALE)
  const [isLoaded, setIsLoaded] = useState(false)

  // https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const debouncedSave = useMemo(() =>
    debounce((cs: PlayerCharacterSheet) => {
      const key = `icrpg_${stringToFilename(cs.character_name)}`
      window.localStorage.setItem(key, JSON.stringify(cs))
      console.log(`"${cs.character_name}" saved to local storage, to view raw data: localStorage.getItem("${key}")`)
    }, SAVE_ON_CHANGE_DELAY_MS), [])

  const handleSave = React.useCallback(cs => debouncedSave(cs), [debouncedSave])

  // set CSS scale
  useEffect(() => {
    document.documentElement.style.setProperty('--global-scale', scale.toString());
  }, [scale])

  // save on data change
  useEffect(() => {
    if (isLoaded) {
      handleSave(_data)
    }
  }, [handleSave, isLoaded, _data])

  // set isLoaded on data change
  useEffect(() => {
    setIsLoaded(true)
  }, [props.data])

  // change CSS, print, and revert CSS
  const printSheet = () => {
    const oldScale = scale
    const oldTitle = window.document.title;

    setScale(PRINT_SCALE)
    setTimeout(() => {
      window.document.title = stringToFilename(`ICRPG ${_data.character_name}`)
      window.print();

      setTimeout(() => {
        setScale(oldScale)
        window.document.title = oldTitle
      }, 10)
    }, 10)
  }

  return (
    <>
      <div className="cs noprint print-button">

        <button onClick={printSheet}>🖨️ Click to Print</button>
        {isDevelopment ?
          <span>
            <button onClick={() => setScale(DEFAULT_SCALE)}>🖥️</button>
            <button onClick={() => setScale(PRINT_SCALE)}>🖨️</button>
          </span> : ""}
        <span className="text-muted">
          Printing from the window will not work!
        </span>&nbsp;
        <button onClick={() => copyToClipboard(JSON.stringify(_data))}>💾 JSON to clipboard</button>
      </div>

      <div className="cs cs-icrpg-master-edition">
        <div className="cs cs-header">
          <CharInfo placeholder="Character Name" name="character_name" className="cs cs-header cs-header-character_name" value={_data.character_name} save={text => setData({ ..._data, character_name: text })} />
          <CharInfo placeholder="" name="world" className="cs cs-header cs-header-world" value={_data.world} save={text => setData({ ..._data, world: text })} />
          <CharInfo placeholder="" name="lifeform" className="cs cs-header cs-header-lifeform" value={_data.lifeform} save={text => setData({ ..._data, lifeform: text })} />
          <CharInfo placeholder="" name="character_type" className="cs cs-header cs-header-character_type" value={_data.character_type} save={text => setData({ ..._data, character_type: text })} />
          <CharInfo placeholder="This is who and what you are, and how you wound up outside ordinary life, on the road to adventure" name="story" className="cs cs-header cs-header-story" value={_data.story} save={text => setData({ ..._data, story: text })} />
        </div>

        <div className="cs cs-core">
          <div className="cs cs-core cs-core-stats-left">
            <CoreStat name="str" value={_data.str} save={stat => setData({ ..._data, str: stat })} className="cs cs-core cs-core-stats-left-str" />
            <CoreStat name="dex" value={_data.dex} save={stat => setData({ ..._data, dex: stat })} className="cs cs-core cs-core-stats-left-dex" />
            <CoreStat name="con" value={_data.con} save={stat => setData({ ..._data, con: stat })} className="cs cs-core cs-core-stats-left-con" />
            <CoreStat name="int" value={_data.int} save={stat => setData({ ..._data, int: stat })} className="cs cs-core cs-core-stats-left-int" />
            <CoreStat name="wis" value={_data.wis} save={stat => setData({ ..._data, wis: stat })} className="cs cs-core cs-core-stats-left-wis" />
            <CoreStat name="cha" value={_data.cha} save={stat => setData({ ..._data, cha: stat })} className="cs cs-core cs-core-stats-left-cha" />
          </div>

          <div className="cs cs-core cs-core-stats-middle">
            <CoreStat name="basic" save={stat => setData({ ..._data, basic: stat })} className="cs cs-core cs-core-stats-middle-basic" value={_data.basic} />
            <CoreStat name="weapons_tools" save={stat => setData({ ..._data, weapons_tools: stat })} className="cs cs-core cs-core-stats-middle-weapons_tools" value={_data.weapons_tools} />
            <CoreStat name="guns" save={stat => setData({ ..._data, guns: stat })} className="cs cs-core cs-core-stats-middle-guns" value={_data.guns} />
            <CoreStat name="energy_magic" save={stat => setData({ ..._data, energy_magic: stat })} className="cs cs-core cs-core-stats-middle-energy_magic" value={_data.energy_magic} />
            <CoreStat name="ultimate" save={stat => setData({ ..._data, ultimate: stat })} className="cs cs-core cs-core-stats-middle-ultimate" value={_data.ultimate} />
          </div>

          <div className="cs cs-core cs-core-stats-right">
            <CharText name="notes-general" placeholder="Character notes" value={_data.notes_general} save={text => setData({ ..._data, notes_general: text })} className="cs cs-core cs-core-stats-right-notes_general" />
            <HealthBar value={_data.hearts} save={hearts => setData({ ..._data, hearts: hearts })} className="cs cs-core cs-core-stats-right-healthbar" />
            <CharText name="notes-healthbar" placeholder="Health status" value={_data.notes_health} save={text => setData({ ..._data, notes_health: text })} className="cs cs-core cs-core-stats-right-notes_healthbar" />
            <BasicStat name="defense" value={_data.defense} save={value => setData({ ..._data, defense: value })} className="cs cs-core cs-core-stats-right-defense" />
            <BasicStat name="herocoins" value={_data.herocoins} save={value => setData({ ..._data, herocoins: value })} className="cs cs-core cs-core-stats-right-herocoins" />
            <BasicStat name="dying" value={_data.dying} save={value => setData({ ..._data, dying: value })} className="cs cs-core cs-core-stats-right-dying" />
          </div>
        </div>

        <div className="cs cs-bottom">
          <LootTable value={_data.loot} save={loot => setData({ ..._data, loot: loot })} className="cs cs-bottom cs-bottom-loot" />
          <CapabilityTable type="abilities" value={_data.abilities} save={abilities => setData({ ..._data, abilities: abilities })} className="cs cs-bottom cs-bottom-abilities" />
          <CapabilityTable type="powers" value={_data.powers} save={powers => setData({ ..._data, powers: powers })} className="cs cs-bottom cs-bottom-powers" />
          <CapabilityTable type="augments" value={_data.augments} save={augments => setData({ ..._data, augments: augments })} className="cs cs-bottom cs-bottom-augments" />
          <CharText name="notes-bottom" placeholder="" value={_data.notes_bottom} save={text => setData({ ..._data, notes_bottom: text })} className="cs cs-bottom cs-bottom-notes_bottom" />
        </div>
      </div>
    </>
  )
}


export default CharacterSheet
