import React from 'react'
import { useState } from 'react'
import { PlayerLoot } from '../../lib/cs'
import LootItem from './LootItem'

import "./LootTable.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: PlayerLoot[]
  onChange?: () => void
  save?: (loot: PlayerLoot[]) => void
}

const LootTable = (props: Props) => {

  const [_table, setTable] = useState(props.value)

  const onChange = props.onChange === undefined ? () => { } : props.onChange

  const save = (loot: PlayerLoot) => {

    // remove the loot from the table
    const newValue = _table.filter(item => item.index !== loot.index)

    // add the new item
    newValue.push(loot)

    // re-sort by index value
    newValue.sort((a,b) => a.index - b.index)

    setTable(newValue)

    if (props.save !== undefined) {
      props.save(newValue)
    }

    if (props.onChange !== undefined) {
      props.onChange()
    }
  }

  return (
    <div className={props.className}>
      <div className="cs loot-table loot-table-container">
        {_table.map((loot, index) => {
          return (
            <LootItem
              index={index}
              key={index}
              value={loot}
              save={save}
              onChange={onChange} />
          )
        })}
      </div>
    </div>
  )
}

export default LootTable
