import React from 'react'
import { useState } from 'react'
import { PlayerLoot } from '../../lib/cs'
import LootCheckbox from './LootCheckbox'

import "./LootItem.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  index: number
  value: PlayerLoot
  onChange?: () => void
  save?: (loot: PlayerLoot) => void
}

const LootItem = (props: Props) => {

  const [_loot, setLoot] = useState(props.value)
 
  const save  =(newValue: PlayerLoot) => {
    setLoot(newValue)
    if (props.save !== undefined) {
      props.save(newValue)
    }
    if (props.onChange !== undefined) {
      props.onChange()
    }    
  }

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    save({
      ..._loot,
      name: e.currentTarget.value
    })
  }

  const equippedChecked = (checked: boolean) => {
    save({
      ..._loot,
      equipped: checked
    })
  }

  const carriedChecked = (checked: boolean) => {
    save({
      ..._loot,
      carried: checked
    })
  }

  return (
    <div className="cs loot-table loot-row">
      <div className="cs loot-table loot-row loot-row-name">
        <textarea spellCheck="false" name={`loot-item-${props.index}`} value={_loot.name} onChange={onChangeName} placeholder="" />
      </div>
      <div className="cs loot-table loot-row loot-row-equipped">
        <LootCheckbox checked={_loot.equipped} onChecked={equippedChecked} />
      </div>
      <div className="cs loot-table loot-row loot-row-carried">
        <LootCheckbox checked={_loot.carried} onChecked={carriedChecked} />
      </div>
    </div>
  )
}

export default LootItem
