import { generateSlug, RandomWordOptions } from "random-word-slugs";
import { actionVerbs, dnD5eMonsters } from "./wordlist"

const getRandomElement = (list: string[]): string => {
  if (list.length < 1) {
    return ""
  }
  return list![Math.floor(Math.random() * list!.length)];
}

const getActionVerb = (): string => getRandomElement(actionVerbs)

const getMonster = (): string => getRandomElement(dnD5eMonsters)

export const getLoadingMessage = (): string => {
  return getActionVerb() + " the " + getMonster()
}

export const getNewSessionCode = (): string => {
  const options: RandomWordOptions<3> = {
    categories: {
      noun: ["animals", "place", "science", "thing"],
      adjective: ["color", "appearance"]
    },
    partsOfSpeech: ["adjective", "noun", "noun"],
  };

  return generateSlug(3, options)
}