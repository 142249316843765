import React, { ChangeEvent } from 'react'
import { MAX_VOLUME, MIN_VOLUME } from '../../lib/audio_file';

import './VolumeSlider.scss'

interface VolumeSliderProps {
  onChange: (value: number) => void
  volume: number
  disabled?: boolean
}

const VolumeSlider = ({ onChange, volume, disabled = false }: VolumeSliderProps) => {

  const onRangeElementChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(parseFloat(e.currentTarget.value))
  }

  return (
    <div className="playback playback-control playback-control-volume">
      <input type="range" min={MIN_VOLUME} max={MAX_VOLUME} value={volume} onChange={onRangeElementChange} disabled={disabled}></input>
    </div>
  )
}

export default VolumeSlider;
