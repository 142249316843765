import React from 'react';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd'
import { connect, ConnectedProps } from 'react-redux';
import ReactGA from 'react-ga4'
import { AudioType, SoundMetadata, SoundState } from '../../lib/audio_file';
import { AudioItem, updateAudioMetadata, updateAudioState } from '../../store/reducers/audioSlice';
import Sound from './Sound';

enum DropType {
  Sound = "SOUND"
}

interface DropResult {
  allowedDropEffect: string
  dropEffect: string
  audioType: AudioType
}

const SoundDropEffect = "move"

const mapDispatchToProps = {
  updateAudioMetadata,
  updateAudioState
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface DraggableSoundProps {
  metadata: SoundMetadata
  soundState: SoundState
  isEditable: boolean
  onMetadataChange: (metadata: SoundMetadata) => void
  onSoundStateChange: (soundState: SoundState) => void
  onRemove: () => void
}

interface SoundCategoryProps extends PropsFromRedux {
  audioType: AudioType
  items: AudioItem[]
  isEditable: boolean
  allowedDropEffect?: string
  onChange: () => void
}

const trackCategoryEvent = (action: string) => {
  ReactGA.event({
    category: "library",
    action: action,
  });
}

const DraggableSound = (props: DraggableSoundProps) => {

  const url = props.metadata.url;

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: DropType.Sound,
      item: {
        url: url
      },
      end(item, monitor) {
        const dropResult = monitor.getDropResult() as DropResult
        if (item && dropResult) {

          const isDropAllowed = dropResult.allowedDropEffect === dropResult.dropEffect
          if (isDropAllowed) {
            props.onMetadataChange({
              ...props.metadata,
              audioType: dropResult.audioType
            })

            trackCategoryEvent("dragdrop-audiotype")

          } else {
            console.error(url, "dropped into type", dropResult.audioType, "which is not allowed")
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [url],
  )

  return (
    <div ref={props.isEditable ? drag : null} style={{ opacity }} className="sound_library sound_library-category sound_library-category-sounds library-sound">
      <Sound
        onMetadataChange={props.onMetadataChange}
        onSoundStateChange={props.onSoundStateChange}
        onRemove={props.onRemove}
        isEditable={props.isEditable}
        metadata={props.metadata}
        soundState={props.soundState} />
    </div>
  )
}

const categoryName = (audioType: AudioType): string => {
  switch (audioType) {
    case AudioType.Background: return "Background"
    case AudioType.Music: return "Music"
    case AudioType.Event: return "Events"
    case AudioType.Character: return "Character / NPC"
    default: return "Sounds"
  }
}

const SoundCategory = (props: SoundCategoryProps) => {

  const { allowedDropEffect = SoundDropEffect, audioType = AudioType.Generic, isEditable } = props

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: DropType.Sound,
      drop: () => ({
        allowedDropEffect,
        audioType
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect, audioType],
  )

  function getDropzoneClassName(isActive: boolean, canDrop: boolean) {
    if (isActive) {
      return 'drop-active'
    } else if (canDrop) {
      return 'drop-enabled'
    } else {
      return ''
    }
  }

  const isActive = canDrop && isOver
  const dropzoneClassName = getDropzoneClassName(isActive, canDrop)

  return (
    <div
      ref={isEditable ? drop : null}
      className={`sound_library sound_library-category sound_library-category-container ${dropzoneClassName} ${isEditable ? "sound_library-category-container-editable" : props.items.length > 0 ? "" : "d-none"}`}>
      <div className="sound_library sound_library-category sound_library-category-header">
        {categoryName(audioType)}
      </div>

      <div className="sound_library sound_library-category sound_library-category-draggable_sounds">
        {props.items.map((item, i) => {

          const onMetadataChange = (metadata: SoundMetadata) => {
            props.updateAudioMetadata({
              url: item.metadata.url,
              metadata: metadata,
            })
            props.onChange()
          }

          const onSoundStateChange = (soundState: SoundState) => {
            props.updateAudioState({
              url: item.metadata.url,
              soundState: soundState
            })
            props.onChange()
          }

          return (
            <DraggableSound
              isEditable={props.isEditable}
              key={item.metadata.url}
              metadata={item.metadata}
              soundState={item.soundState}
              onMetadataChange={onMetadataChange}
              onSoundStateChange={onSoundStateChange}
              onRemove={props.onChange}
            />
          )
        })}
      </div>

      {
        isEditable ?
          <div className="sound_library sound_library-category sound_library-category-footer">
            {isActive ? 'Release to drop' : 'Drag a sound here'}
          </div>
          :
          ""
      }

    </div>
  )
}

export default connector(SoundCategory);
