export const SAMPLE_RATE = 44100

// @ts-ignore
const availableCtx = window.AudioContext || window.webkitAudioContext

const options: AudioContextOptions = {
  sampleRate: SAMPLE_RATE
}

const globalAudioContext = new availableCtx(options)

if (globalAudioContext.state === 'suspended') {
  globalAudioContext.resume();
}

/**
 * Returns the global audio context
 */
export const getAudioContext = () => {
  return globalAudioContext
};
