import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer';

// expose hooks
export { useAppDispatch, useAppSelector } from './hooks'

const composedEnhancer = applyMiddleware(thunkMiddleware)

const store = configureStore({
  reducer: rootReducer,
  enhancers: [composedEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // thunk: {
      //   extraArgument: myCustomApiService,
      // },
      // since we are storing Audio in the store, we need to disable the serializable check since "Audio" is a class
      serializableCheck: false,
    }),
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
