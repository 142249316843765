import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { AudioFile, MAX_VOLUME, PlayableSound, SoundMetadata, SoundState } from '../../lib/audio_file'
import { SerializedAudioState } from '../../lib/api'

export interface AudioItem {
  audio: AudioFile
  soundState: SoundState
  metadata: SoundMetadata
  isReady: boolean
  hasError: boolean
  errorMessage: string
  isLoading: boolean
}

const DEFAULT_AUDIO_ITEM: any = {
  isReady: false,
  hasError: false,
  errorMessage: false,
  isLoading: false
}

export interface AudioState {
  files: { [key: string]: AudioItem; },
  playbackEnabled: boolean
}

const initialState: AudioState = {
  files: {},
  playbackEnabled: true
}

const soundStateWithDefaults = (soundState: SoundState): SoundState => {
  return {
    volume: soundState.volume === undefined ? MAX_VOLUME : soundState.volume,
    loop: soundState.volume === undefined ? false : soundState.loop
  }
}

const stopAllAudioInDraftState = (state: WritableDraft<AudioState>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [url, item] of Object.entries(state.files)) {
    item.audio.Stop()
  }
}

/**
 * serializeAudioState returns a serialized version of the audio state
 * @param state 
 * @returns 
 */
export const serializeAudioState = (state: AudioState): SerializedAudioState => {
  const sounds = new Array<PlayableSound>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [url, item] of Object.entries(state.files)) {
    sounds.push({
      ...item.metadata,
      ...item.soundState
    })
  }

  return {
    sounds: sounds
  }
}

export const audioSlice = createSlice({
  name: 'audio',

  initialState,

  reducers: {

    setIsLoading: (state, action: PayloadAction<{ url: string, isLoading: boolean }>) => {
      const { url } = action.payload

      if (state.files[url] !== undefined) {
        return
      }

      state.files[url] = {
        ...state.files[url],
        isLoading: action.payload.isLoading
      }

    },

    setErrorMessage: (state, action: PayloadAction<{ url: string, errorMessage: string }>) => {
      const { url } = action.payload

      if (state.files[url] !== undefined) {
        return
      }

      state.files[url] = {
        ...state.files[url],
        isReady: false,
        hasError: true,
        errorMessage: action.payload.errorMessage
      }

    },

    setIsReady: (state, action: PayloadAction<{ url: string }>) => {
      const { url } = action.payload

      if (state.files[url] !== undefined) {
        return
      }

      state.files[url] = {
        ...state.files[url],
        isReady: true,
        hasError: false,
        errorMessage: "",
      }

    },

    /**
     * addAudioFile is a gatekeeper to AudioFile files
     * If an audio file is attempted to be created for an existing URL, that will be ignored 
     * @param state 
     * @param action 
     */
    addAudioFile: (state, action: PayloadAction<{ audioFile: AudioFile, metadata: SoundMetadata, soundState: SoundState }>) => {
      const { metadata, soundState } = action.payload
      const url = metadata.url

      const ss = soundStateWithDefaults(soundState)

      if (state.files[url] !== undefined) {
        // if the audio file exists, update the state for it
        state.files[url].soundState = ss
        state.files[url].audio.SetLoop(ss.loop)
        state.files[url].audio.SetVolume(ss.volume)
        return
      }

      state.files[url] = {
        ...DEFAULT_AUDIO_ITEM,
        audio: action.payload.audioFile,
        metadata: metadata,
        soundState: soundState,
      }
    },

    /**
     * deleteAudioFile stops an audio file before deleting it
     * @param state 
     * @param action 
     */
    deleteAudioFile: (state, action: PayloadAction<{ url: string }>) => {
      const { url } = action.payload

      if (state.files[url] === undefined) {
        return
      }

      // stop before deleting
      try {
        state.files[url].audio.Stop()
        state.files[url].audio.Cleanup()
      } catch (e: any) {
        console.log("unable to stop", url, "before deleting", e)
      }

      // use destructuring to remove element for the url
      const { [url]: removedItem, ...remainingItems } = state.files;

      state.files = remainingItems
    },

    /**
     * updateAudioMetadata updates the metadata for an audio file 
     * @param state 
     * @param action 
     */
    updateAudioMetadata: (state, action: PayloadAction<{ url: string, metadata: SoundMetadata }>) => {
      const { url, metadata } = action.payload

      if (state.files[url] === undefined) {
        console.error("audio doesn't exist for", url)
      } else {
        state.files[url].metadata = metadata
      }
    },

    /**
     * updateAudioState updates the state of an audio file 
     * @param state 
     * @param action 
     */
    updateAudioState: (state, action: PayloadAction<{ url: string, soundState: SoundState }>) => {
      const { url, soundState } = action.payload

      const ss = soundStateWithDefaults(soundState)


      if (state.files[url] === undefined) {
        console.error("audio doesn't exist for", url)
      } else {
        state.files[url].soundState = ss
        state.files[url].audio.SetLoop(ss.loop)
        state.files[url].audio.SetVolume(ss.volume)
      }
    },


    stopAll: (state) => {
      stopAllAudioInDraftState(state)
    },

    setPlaybackEnabled: (state, action: PayloadAction<boolean>) => {
      if (state.playbackEnabled && action.payload === false) {
        stopAllAudioInDraftState(state)
      }
      state.playbackEnabled = action.payload
    },

    initLibrary: (state) => {
      stopAllAudioInDraftState(state)
      state.files = {}
    },

  },
})

export const { addAudioFile, updateAudioMetadata, updateAudioState, stopAll, setPlaybackEnabled, setErrorMessage, setIsReady, setIsLoading, initLibrary, deleteAudioFile } = audioSlice.actions

export const reducer = audioSlice.reducer

export default audioSlice
