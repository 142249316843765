import './Sound.scss'

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import debounce from 'lodash/debounce'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCog } from '@fortawesome/free-solid-svg-icons'
import { AudioType, PlayState, MediaState, DEFAULT_MEDIA_STATE, SoundMetadata, SoundState } from '../../lib/audio_file';
import { RootState } from '../../store';
import { deleteAudioFile } from '../../store/reducers/audioSlice'
import { connect, ConnectedProps } from 'react-redux';
import ControlButton, { ButtonType } from './ControlButton';
import VolumeSlider from './VolumeSlider';

const trackSoundEvent = (action: string) => {
  ReactGA.event({
    category: "sound",
    action: action,
  });
}

// operate directly on the audio stored in Redux, we assume it's there!
const mapStateToProps = (state: RootState, s: SoundPropsState) => ({
  play: () => state.audio.files[s.metadata.url].audio.Play(),
  playDelayed: (delay: number) => state.audio.files[s.metadata.url].audio.PlayLoopDelayed(delay),
  pause: () => state.audio.files[s.metadata.url].audio.Pause(),
  stop: () => state.audio.files[s.metadata.url].audio.Stop(),
  setLoop: (loop: boolean) => state.audio.files[s.metadata.url].audio.SetLoop(loop),
  setVolume: (volume: number) => state.audio.files[s.metadata.url].audio.SetVolume(volume),
  isReady: state.audio.files[s.metadata.url].isReady,
  hasError: state.audio.files[s.metadata.url].hasError,
  errorMessage: state.audio.files[s.metadata.url].errorMessage,
  isLoading: state.audio.files[s.metadata.url].isLoading,
  audioFile: state.audio.files[s.metadata.url].audio,
})

const mapDispatchToProps = {
  deleteAudioFile
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export interface SoundPropsState {
  metadata: SoundMetadata
  soundState: SoundState
}

export interface SoundProps extends PropsFromRedux {
  isEditable?: boolean
  metadata: SoundMetadata
  soundState: SoundState
  onMetadataChange: (metadata: SoundMetadata) => void
  onSoundStateChange: (soundState: SoundState) => void
  onRemove: () => void
}

const Sound = ({ isEditable = false, ...props }: SoundProps) => {

  const { soundState, metadata, audioFile } = props

  const [soundVolume, setSoundVolume] = useState(soundState.volume)
  const [mediaState, setMediaState] = useState(DEFAULT_MEDIA_STATE)
  const [repeatSeconds, setRepeatSeconds] = useState(-1)

  useEffect(() => {
    // if audioFile changes, connect media state callback to this component 
    audioFile.SetMediaStateCallback((newState: MediaState) => {
      setMediaState(newState)
    })

  }, [audioFile, setMediaState])

  const stopRepeat = () => {
    props.stop()
    setRepeatSeconds(-1)
  }

  const LoopButton = ({ loop = false }): JSX.Element => {

    const loopButtonClick = () => {
      const newLoopState = !loop
      props.setLoop(newLoopState)
      props.onSoundStateChange({ ...props.soundState, loop: newLoopState })
      trackSoundEvent("playback-toogle-loop")
    }

    // disable loop button if any of these are true
    const hasSamples = metadata.samples === undefined ? false : metadata.samples.length > 0
    const isOnRepeat = repeatSeconds > 0
    const isPlayable = mediaState.playable === false

    return <ControlButton
      type={ButtonType.Loop}
      onClick={loopButtonClick}
      isActive={loop}
      disabled={isEditable || isPlayable || isOnRepeat || hasSamples} />

  }

  const PlayPauseButton = (): JSX.Element => {
    if (mediaState.playable === false) {
      return <ControlButton type={ButtonType.Loading} disabled={isEditable} />
    } else if (repeatSeconds > 0) {
      const stopRepeatWrapper = () => {
        trackSoundEvent("playback-stop-repeat")
        stopRepeat()
      }
      return <ControlButton type={ButtonType.Stop} onClick={stopRepeatWrapper} disabled={isEditable} />
    } else {
      if (mediaState.playState === PlayState.Playing) {
        const pauseWrapper = () => {
          trackSoundEvent("playback-pause")
          props.pause()
        }
        return <ControlButton type={ButtonType.Pause} onClick={pauseWrapper} disabled={isEditable} />
      } else {
        const playWrapper = () => {
          trackSoundEvent("playback-play")
          props.play()
        }
        return <ControlButton type={ButtonType.Play} onClick={playWrapper} disabled={isEditable} />
      }
    }
  }

  const IntervalPlayButton = () => {
    const isPlayable = mediaState.playable === true
    const hasSamples = metadata.samples === undefined ? false : metadata.samples.length > 0

    const onSelect = (seconds: number) => {
      stopRepeat()
      if (seconds > 0) {
        setRepeatSeconds(seconds)
        props.playDelayed(seconds)
      }
      trackSoundEvent("playback-set-repeat")
    }

    return <ControlButton
      type={ButtonType.Repeat}
      onClick={onSelect}
      isActive={repeatSeconds > 0}
      activeValue={repeatSeconds}
      disabled={isEditable || !isPlayable || hasSamples} />
  }

  const RemoveButton = () => {
    const onClick = () => {
      props.deleteAudioFile({ url: props.metadata.url })
      props.onRemove()
      trackSoundEvent("remove")
    }

    return <ControlButton
      type={ButtonType.Remove}
      onClick={onClick}
      disabled={!isEditable}
    />
  }

  const sampleCount = (): string => {
    if (metadata.samples === undefined) {
      return ""
    }
    if (metadata.samples.length > 0) {
      return `x${metadata.samples.length}`
    }
    return ""
  }

  const onVolumeChange = (v: number) => {
    setSoundVolume(v)
    props.setVolume(v)
    props.onSoundStateChange({ ...props.soundState, volume: v })

    // prevent event spam
    debounce(() => trackSoundEvent("playback-change-volume"), 1000)
  }

  return (
    <div className={`sound sound-container ${isEditable ? "sound-container-edit" : "sound-container-playback"}`}>

      <div className={`sound sound-loading ${props.isLoading ? "" : "d-none"}`}>
        {metadata.title}
        <br />
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
          loading {metadata.url}
        </Tooltip>}>
          <span className="d-inline-block">
            <FontAwesomeIcon icon={faCog} spin={true} />
          </span>
        </OverlayTrigger>
      </div>

      <div className={`sound sound-error ${props.hasError ? "" : "d-none"}`}>
        {metadata.title}
        <br />
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
          {props.errorMessage}
        </Tooltip>}>
          <span className="d-inline-block">
            <span className="sound sound-error sound-error-icon">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
            <span className="sound sound-error sound-error-message">
              Failed to load
            </span>
          </span>
        </OverlayTrigger>
      </div>

      <div className={`sound sound-media ${props.isLoading || props.hasError ? "d-none" : ""} ${mediaState.playable ? "sound-media-playable" : "sound-media-disabled"}`}>

        <div title={`${metadata.title}: ${metadata.description}`} className="sound sound-media sound-media-header">
          {metadata.title}
          <span className="sampleCount">{sampleCount()}</span>
          {isEditable ? <p className="text-sm-center text-muted">{metadata.description}</p> : ""}
        </div>

        <div className={`sound sound-media library-remove ${isEditable ? "" : "d-none"}`}>
          <RemoveButton />
        </div>

        <div className={`sound sound-media sound-media-controls  ${isEditable ? "d-none" : ""}`}>

          <VolumeSlider disabled={isEditable} volume={soundVolume} onChange={onVolumeChange} />

          <div className="playback">
            <PlayPauseButton />
            <LoopButton loop={mediaState.loop} />
            {metadata.audioType !== AudioType.Music ? <IntervalPlayButton /> : ""}
          </div>
        </div>


      </div>
    </div>
  );

}

export default connector(Sound);
