import { getAudioContext } from "./context";
import util from 'audio-buffer-utils'

type URL = string

export enum AudioType {
  /**
   * Sounds that don't fit in a category
   */
  Generic = "GENERIC",
  /**
  * Background sounds such as clinking glasses, gunfire
  */
  Background = "BACKGROUND",
  /**
   * Effects such as explosion, alert sound, door opening
   */
  Event = "EVENT",
  /**
   * Any audio that a character would do 
   */
  Character = "CHARACTER",
  /**
   * Background audio music
   */
  Music = "MUSIC"
}

export enum PlayState {
  Stopped = "STOPPED",
  Paused = "PAUSED",
  Playing = "PLAYING"
}

export interface MediaState {
  playable: boolean,
  playState: PlayState,
  loop: boolean,
  volume: number,
  duration: number,
}

export interface Sample {
  offset: number;
  duration: number;
}

export interface SoundState {
  volume: number;
  loop: boolean;
}

export interface SoundMetadata {
  title: string;
  description: string;
  audioType: AudioType;
  url: URL;
  samples?: Sample[];
}

export interface PlayableSound extends SoundMetadata, SoundState {
}

export type MediaStateCallbackFunction = (state: MediaState) => void;

export const MIN_VOLUME = 0.0
export const MAX_VOLUME = 100.0

export const DEFAULT_MEDIA_STATE: MediaState = {
  playable: false,
  playState: PlayState.Stopped,
  loop: false,
  volume: MAX_VOLUME,
  duration: 0.0
}


const getRandomSample = (samples: Sample[]): Sample => {
  return samples![Math.floor(Math.random() * samples!.length)];
}

/**
 * AudioFile represents an audio file that is playable
 */
export class AudioFile {
  private ctx: AudioContext;
  private buffer: AudioBuffer;
  private sourceNode: AudioBufferSourceNode | null;
  private gainNode: GainNode;
  private volume: number;
  private playState: PlayState;
  private startedAt: number;
  private pausedAt: number;
  private loop: boolean;
  private mediaStateCallbackFn: MediaStateCallbackFunction;
  private samples: Sample[];
  private url: string;
  private isBufferReady: boolean;

  constructor(url: string, soundState: SoundState, samples: Sample[] = []) {
    this.url = url
    this.samples = samples

    this.ctx = getAudioContext()
    this.sourceNode = null

    this.gainNode = this.ctx.createGain()
    this.volume = soundState.volume === undefined ? MAX_VOLUME : soundState.volume

    // ensure gain node is updated
    this.SetVolume(this.volume)

    this.loop = soundState.loop === undefined ? false : soundState.loop
    this.playState = PlayState.Stopped;
    this.startedAt = Date.now()
    this.pausedAt = Date.now()

    // add an empty 0.001s buffer
    this.buffer = util.create(0.001 * this.ctx.sampleRate)
    this.isBufferReady = false

    // noop callback
    this.mediaStateCallbackFn = (state: MediaState) => { }
  }


  /**
   * SetBuffer sets the buffer to be used for audio playback
   * @param buffer 
   */
  public SetBuffer(arrayBuffer: ArrayBuffer): Promise<boolean> {

    const setBuffer = (audioBuffer: AudioBuffer) => {
      this.buffer = audioBuffer
      this.isBufferReady = true
      
      // trigger media state update
      this.updateState()
    }

    return new Promise<boolean>((resolve, reject) => {
      this.ctx.decodeAudioData(arrayBuffer)
        .then((buffer) => {
          setBuffer(buffer)
          resolve(true)
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    })
  }

  /**
   * Updates the internal media state
   */
  private updateState(playState?: PlayState) {
    const ps = playState === undefined ? this.playState : playState
    this.playState = ps
    if (this.mediaStateCallbackFn !== undefined) {
      this.mediaStateCallbackFn(this.GetMediaState())
    }
  }

  private createLoopingDelayedSource(delaySeconds: number) {
    this.sourceNode = this.ctx.createBufferSource()

    // add empty buffer (delay seconds) to end of actual audio buffer
    this.sourceNode.buffer = util.concat(this.buffer, util.create(delaySeconds * this.ctx.sampleRate))

    // force loop
    this.sourceNode.loop = true

    this.sourceNode.connect(this.gainNode)
  }

  private createSource() {
    this.sourceNode = this.ctx.createBufferSource()
    this.sourceNode.buffer = this.buffer
    this.sourceNode.loop = this.loop
    this.sourceNode.connect(this.gainNode)
  }

  private playSource() {

    if (this.sourceNode === null) {
      console.error("source node not defined")
      return
    }

    this.gainNode.connect(this.ctx.destination)

    // resume from paused state
    if (this.playState === PlayState.Paused) {
      this.startedAt = Date.now() - this.pausedAt;
      this.sourceNode.start(0, this.pausedAt / 1000);

      // play the sound
    } else {
      this.startedAt = Date.now();

      // play the sound
      if (this.samples !== undefined && this.samples!.length > 0) {

        const sample = getRandomSample(this.samples!)

        if (this.sourceNode.loop === true) {
          console.log("SAMPLES WITH loop detected", sample)
          this.sourceNode.loopStart = sample.offset
          this.sourceNode.loopEnd = sample.offset + sample.duration
          console.log("set loop start and end to:", sample.offset + sample.duration, sample.duration)
        }
        this.sourceNode.start(0, sample.offset, sample.duration)
      } else {
        this.sourceNode.start(0)
      }
    }

    // update media state when source completes
    this.sourceNode.addEventListener("ended", (ev: Event) => {
      this.updateState(PlayState.Stopped)
      try {
        this.sourceNode!.disconnect(this.gainNode)
      }
      catch (error) {
        // console.log("failed to disconnect destination from gain")
      }
    })

    this.updateState(PlayState.Playing)
  }

  public SetMediaStateCallback(fn: MediaStateCallbackFunction) {
    this.mediaStateCallbackFn = fn
    this.updateState()
  }

  public GetMediaState(): MediaState {
    return {
      playable: this.isBufferReady,
      playState: this.playState,
      loop: this.loop,
      volume: this.volume,
      duration: this.buffer.duration,
    }
  }

  public GetSoundState(): SoundState {
    return {
      loop: this.loop,
      volume: this.volume,
    }
  }

  public SetLoop(loop: boolean) {

    // if currently looping, we must tell the source node to not loop when playback ends
    if (this.sourceNode !== null) {
      this.sourceNode!.loop = loop
    }

    this.loop = loop
    this.updateState()
  }

  public Cleanup() {
    if (this.gainNode !== null) {
      try {
        this.gainNode.disconnect(this.ctx.destination)
      }
      catch (error) {
        // console.log("failed to disconnect destination from gain")
      }
    }
  }
  public PlayFadeIn() {
    // TODO - create source node using fade in and fade out
    // https://webaudioapi.com/book/Web_Audio_API_Boris_Smus_html/ch02.html
  }

  public PlayLoopDelayed(delaySeconds: number) {
    this.createLoopingDelayedSource(delaySeconds)
    this.playSource()
  }

  public Play() {
    this.createSource()
    this.playSource()
  }

  public SetVolume(volume: number) {
    if (volume < MIN_VOLUME || volume > MAX_VOLUME) {
      console.error(`volume ${volume} is out of allowed range (${MIN_VOLUME}-${MAX_VOLUME}), using to ${MAX_VOLUME}`)
      volume = MAX_VOLUME
    }

    this.volume = volume

    //  use an x*x curve (x-squared) since simple linear (x) does not sound as good
    const fraction = this.volume / 100
    this.gainNode.gain.value = fraction * fraction;

    this.updateState()
  }

  public Pause() {
    if (this.sourceNode === null) {
      return
    }
    this.sourceNode.stop()
    this.pausedAt = Date.now() - this.startedAt;
    this.updateState(PlayState.Paused)
  }

  public Stop() {
    if (this.sourceNode === null) {
      return
    }
    this.sourceNode.stop()
    this.updateState(PlayState.Stopped)
  }
}

