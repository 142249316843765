import React, { ReactElement } from 'react'

import "./LootCheckbox.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean
  onChecked: (checked: boolean) => void
}

const LootCheckbox = (props: Props): ReactElement => {

  const { checked, onChecked } = props

  const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

  return (
    <div className={`${props.className} cs cs-loot cs-loot-checkbox`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChecked(e.currentTarget.checked)} 
        id={id} />
        <label htmlFor={id}></label>
    </div>
  )
}

export default LootCheckbox
