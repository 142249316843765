
const LOOT_COUNT = 20
const ABIILITY_COUNT = 5
const POWER_COUNT = 3
const AUGMENT_COUNT = 4

export const DELETE_KEY_CODES = ["Backspace", "Delete", " ", "Escape"]

/**
 * Core player stat
 */
export interface PlayerBaseStat {
  base: number;
  lifeform: number;
  loot: number;
}

/**
 * Represents a single piece of loot
 */
export interface PlayerLoot {
  index: number;
  name: string;
  equipped: boolean;
  carried: boolean;
}

/**
 * Represents abiltiies, powers, etc.
 */
export interface PlayerCapability {
  index: number;
  name: string;
}

/**
 * Represents a single player ability
 */
export interface PlayerAbility extends PlayerCapability {
}

/**
 * Represents a single player mastery
 */
export interface PlayerMastery extends PlayerCapability {
}

/**
 * Represents a single player augment
 */
export interface PlayerAugment extends PlayerCapability {
}

/**
 * Represents a single player capability
 */
export interface PlayerPower extends PlayerCapability {
}

/**
 * ICRPG Master Edition character sheet
 */
export interface PlayerCharacterSheet {
  character_name: string;
  world: string;
  lifeform: string;
  character_type: string;
  story: string;
  notes_general: string;
  notes_health: string;
  notes_bottom: string;
  hearts: number;
  str: PlayerBaseStat;
  dex: PlayerBaseStat;
  con: PlayerBaseStat;
  int: PlayerBaseStat;
  wis: PlayerBaseStat;
  cha: PlayerBaseStat;
  basic: PlayerBaseStat;
  weapons_tools: PlayerBaseStat;
  guns: PlayerBaseStat;
  energy_magic: PlayerBaseStat;
  ultimate: PlayerBaseStat;
  defense: number;
  herocoins: number;
  dying: number;
  loot: PlayerLoot[];
  abilities: PlayerAbility[];
  augments: PlayerAugment[];
  powers: PlayerPower[];
  masteries: PlayerMastery[];
}

/**
 * Returns a string without spaces and all lowercase
 * @param character_name 
 * @returns string
 */
export const stringToFilename = (value: string): string => {
  return value.toLowerCase().replaceAll(" ", "_")
}

const defaultBaseStat = (): PlayerBaseStat => {
  return {
    base: 0,
    lifeform: 0,
    loot: 0
  }
}

/**
 * Provides empty default values
 * @returns PlayerCharacterSheet
 */
export const defaultCharacterSheetData = (): PlayerCharacterSheet => {
  const bs = defaultBaseStat();

  const lootTable: PlayerLoot[] = [];
  for (let i = 0; i < LOOT_COUNT; i++) {
    lootTable.push({
      index: i,
      name: "",
      equipped: false,
      carried: false
    })
  }

  const abilitiesTable: PlayerCapability[] = [];
  for (let i = 0; i < ABIILITY_COUNT; i++) {
    abilitiesTable.push({
      index: i,
      name: ""
    })
  }

  const powersTable: PlayerCapability[] = [];

  for (let i = 0; i < POWER_COUNT; i++) {
    powersTable.push({
      index: i,
      name: ""
    })
  }

  const augmentsTable: PlayerCapability[] = [];

  for (let i = 0; i < AUGMENT_COUNT; i++) {
    augmentsTable.push({
      index: i,
      name: ""
    })
  }

  return {
    character_name: "",
    world: "",
    lifeform: "",
    character_type: "",
    story: "",
    notes_general: "",
    notes_health: "",
    notes_bottom: "",
    hearts: 1,
    str: bs,
    dex: bs,
    con: bs,
    int: bs,
    wis: bs,
    cha: bs,
    basic: bs,
    weapons_tools: bs,
    guns: bs,
    energy_magic: bs,
    ultimate: bs,
    defense: 0,
    herocoins: 0,
    dying: 0,
    loot: lootTable,
    abilities: abilitiesTable,
    powers: powersTable,
    augments: augmentsTable,
    masteries: [],
  }
}

/**
 * Provides sample data to test the character sheet UI
 * @returns PlayerCharacterSheet
 */
export const testCharacterSheetData = (): PlayerCharacterSheet => {
  const bs = {
    base: 1,
    lifeform: 1,
    loot: 1
  }

  const lewt = [
    {
      index: 0,
      name: "Handshake from Hell: Trap, An odd hand sculpture, when touched latches on with a STR +5 grip",
      equipped: false,
      carried: false,
    },
    {
      index: 1,
      name: "Tentacle Glove: Item, Roll Magical for Basic Effort, +2 STR, -3 CHA, A hideous barbed whip replaces one arm",
      equipped: true,
      carried: true,
    },
    {
      index: 2,
      name: "Ring of Tears: Item, -1 WIS, To remove the ring a willing recipient must be found",
      equipped: true,
      carried: true
    },
    {
      index: 3,
      name: "Ravager Axe: Weapon, +1 STR, Once engaged with an enemy, you are compelled to finish the kill",
      equipped: true,
      carried: true,
    },
    {
      index: 4,
      name: "Frogman Gear: Item, Mask, fins, and a small air bladder for 4 ROUNDS spent underwater, reusable",
      equipped: false,
      carried: true,
    }
  ]

  const lootTable: PlayerLoot[] = [];
  const lLen = Object.entries(lewt).length
  lewt.forEach((item) => lootTable.push(item))
  for (let i = lLen; i < 20; i++) {
    lootTable.push({
      index: i,
      name: `index  ${i}`,
      equipped: false,
      carried: false
    })
  }

  const abilities: PlayerAbility[] = [{
    index: 0,
    name: "ASSASSIN: If a target doesn’t know you’re there, your first attack cannot miss"
  }]

  const abilitiesTable: PlayerCapability[] = [];
  const aLen = Object.entries(abilities).length
  abilities.forEach((item) => abilitiesTable.push(item))
  for (let i = aLen; i < 5; i++) {
    abilitiesTable.push({
      index: i,
      name: `index  ${i}`
    })
  }

  const powers: PlayerPower[] = [{
    index: 0,
    name: "INVISIBLE: By degrees, you have incredible stealth"
  }]

  const powersTable: PlayerCapability[] = [];
  const pLen = Object.entries(powers).length
  powers.forEach((item) => powersTable.push(item))
  for (let i = pLen; i < 3; i++) {
    powersTable.push({
      index: i,
      name: `index  ${i}`
    })
  }

  const augments: PlayerAugment[] = [{
    index: 0,
    name: "Razorgirl: Razor-sharp retractable blades underneath your fingernails, each double-edged and four centimeters in length"
  }]

  const augmentsTable: PlayerCapability[] = [];
  const augLen = Object.entries(augments).length
  augments.forEach((item) => augmentsTable.push(item))
  for (let i = augLen; i < 4; i++) {
    augmentsTable.push({
      index: i,
      name: `index  ${i}`
    })
  }


  const masteries: PlayerMastery[] = [{
    index: 0,
    name: "THIEF: Foes make a WIS roll to detect you, even afer being attacked"
  }]

  return {
    character_name: "character name",
    world: "world",
    lifeform: "lifeform",
    character_type: "character type",
    story: "A long and interesting tale",
    notes_general: "some general notes",
    notes_health: "some health notes",
    notes_bottom: "notes on bottom",
    hearts: 2,
    str: bs,
    dex: bs,
    con: bs,
    int: bs,
    wis: bs,
    cha: bs,
    basic: bs,
    weapons_tools: bs,
    guns: bs,
    energy_magic: bs,
    ultimate: bs,
    defense: 2,
    herocoins: 1,
    dying: 6,
    loot: lootTable,
    abilities: abilitiesTable,
    powers: powersTable,
    augments: augmentsTable,
    masteries: masteries,
  }

}
