import React, { ChangeEvent, FormEvent, useState } from 'react';
import { faDownload, faSearch, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Container, InputGroup, Form, FormControl, Button, Nav } from 'react-bootstrap';
import api from '../lib/api';
import { MIN_LIBRARY_CODE_LENGTH } from '../lib/validate';

import './Navigation.scss'
import logo from "../assets/img/logo.png"

interface NavigationProps {
  loadActive: boolean
  saveActive: boolean
  libraryCode: string
}

function Navigation(props: NavigationProps) {

  const { loadActive, saveActive } = props
  const [code, setCode] = useState(props.libraryCode)

  const disabled = code.length < MIN_LIBRARY_CODE_LENGTH

  const loadSession = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (code.length < MIN_LIBRARY_CODE_LENGTH) {
      return
    }
    // @ts-ignore
    window.location = api.getLibraryCodePath(code)
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="nav">
        <Container>

          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}RPG Party
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link href="/library/audio/new">Create Audio Library</Nav.Link>
            <Nav.Link href="/cs">Character Sheet</Nav.Link>
            </Nav>
            <Form onSubmit={loadSession} className="d-flex">

              <InputGroup>
                <FormControl
                  aria-label="Library Code"
                  placeholder="Enter Library Code"

                  onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.currentTarget.value)}
                  value={code} />

                <Button disabled={disabled} type="submit" title="View" variant="primary" onClick={() => { }}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup>

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={`nav uploading-active ${saveActive ? "" : "d-none"}`}>
        <FontAwesomeIcon className="fa-bounce" icon={faDownload} />
      </div>

      <div className={`nav downloading-active ${loadActive ? "" : "d-none"}`}>
        <FontAwesomeIcon className="fa-bounce" icon={faUpload} />
      </div>

    </>
  );
}

export default Navigation;
