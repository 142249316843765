export const MIN_LIBRARY_CODE_LENGTH = 8

export const LIBRARY_CODE_ALLOWED_CHARS = ["-", "!", "@", "$", "&", "*"]

const LIBRARY_CODE_ALLOWED_CHARS_REGEXP = "^[a-zA-Z0-9" + LIBRARY_CODE_ALLOWED_CHARS.join("").replace("-", "\\-") + "]+$"

export const validateLibraryCode = (code: string): boolean => {
  var regex = new RegExp(LIBRARY_CODE_ALLOWED_CHARS_REGEXP);
  if (regex.test(code)) {
    return true;
  }
  return false
}