import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SoundsState {
  isEditable: boolean
  libraryCode: string
  saveActive: boolean
  loadActive: boolean
  dirty: boolean
}

const initialState: SoundsState = {
  isEditable: false,
  libraryCode: "",
  saveActive: false,
  loadActive: false,
  dirty: false
}


export const soundsSlice = createSlice({
  name: 'sounds',

  initialState,

  reducers: {

    setEditable: (state, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload
    },

    setSaveActive: (state, action: PayloadAction<boolean>) => {
      state.saveActive = action.payload
    },

    setLoadActive: (state, action: PayloadAction<boolean>) => {
      state.loadActive = action.payload
    },

    setDirty: (state, action: PayloadAction<boolean>) => {
      state.dirty = action.payload
    },

    setLibraryCode: (state, action: PayloadAction<string>) => {
      state.libraryCode= action.payload
    },

  },
})

export const { setEditable, setSaveActive, setLoadActive, setDirty, setLibraryCode } = soundsSlice.actions


export const reducer = soundsSlice.reducer

