import React, { ReactElement, useState } from 'react'
import { DELETE_KEY_CODES, PlayerBaseStat } from '../../lib/cs'

import "./CoreStat.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  value?: PlayerBaseStat
  onChange?: () => void
  save?: (value: PlayerBaseStat) => void
}

const CoreStat = (props: Props): ReactElement => {
  const { name } = props
  const baseStat = props.value !== undefined ? props.value : {
    base: 0,
    lifeform: 0,
    loot: 0
  }

  const [_baseStat, setBaseStat] = useState<PlayerBaseStat>(baseStat)

  const total = (): number => {
    return _baseStat.base + _baseStat.lifeform + _baseStat.loot
  }

  const shouldBlur = (e: any) => {
    e.target.blur();
  }

  const getNumber = (e: React.KeyboardEvent<HTMLInputElement>): number => {

    if (e.defaultPrevented) {
      return 0; // Should do nothing if the default action has been cancelled
    }

    var handled = false;
    if (e.key !== undefined) {
      const value = e.key

      // on delete or space, reset value
      if (DELETE_KEY_CODES.includes(value)) {
        return 0
      }

      const intValue = parseInt(e.key)
      if (isFinite(intValue)) {
        return intValue
      }
    }

    if (handled) {
      // Suppress "double action" if event handled
      e.preventDefault();
    }

    return 0;
  }

  const updateBaseStat = (newBaseStat: PlayerBaseStat) => {
    setBaseStat(newBaseStat)
    if (props.onChange !== undefined) {
      props.onChange()
    }
    if (props.save !== undefined) {
      props.save(newBaseStat)
    }
  }

  const noop = () => { }

  return (
    <div className={props.className}>
      <div className="cs cs-stat cs-stat-total">
        <input type="text" name={`stats-${name}`} value={total() === 0 ? "" : total()} className="clear-stat-total" readOnly onFocus={shouldBlur} tabIndex={-1} />
      </div>
      <div className="cs cs-stat cs-stat-base">
        <input type="text"
          value={_baseStat.base === 0 ? "" : _baseStat.base}
          onChange={noop}
          onKeyUp={(e) => {
            updateBaseStat({
              ..._baseStat,
              base: getNumber(e)
            })
          }}
          className="clear-stat-lifeform" />
        <input type="text"
          value={_baseStat.lifeform === 0 ? "" : _baseStat.lifeform}
          onChange={noop}
          onKeyUp={(e) => {
            updateBaseStat({
              ..._baseStat,
              lifeform: getNumber(e)
            })
          }}
          className="clear-stat-loot" />
        <input type="text"
          value={_baseStat.loot === 0 ? "" : _baseStat.loot}
          onChange={noop}
          onKeyUp={(e) => {
            updateBaseStat({
              ..._baseStat,
              loot: getNumber(e)
            })
          }}
          className="clear-stat-loot" />
      </div>
    </div>
  )
}

export default CoreStat
