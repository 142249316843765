import React, { ReactElement, useState } from 'react'

import "./CharText.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  value?: string
  placeholder?: string
  onChange?: () => void
  save?: (text: string) => void
}


const CharText = ({ value = "", placeholder="", ...props }: Props): ReactElement => {

  const { name } = props

  const [_value, setValue] = useState(value)

  return (
    <div className={props.className}>
      <textarea spellCheck="false" placeholder={placeholder} name={name} value={_value} onChange={(e) => {
        const newText = e.currentTarget.value;
        setValue(newText)
        if (props.onChange !== undefined) {
          props.onChange()
        }
        if (props.save !== undefined) {
          props.save(newText)
        }
      }} className={`cs cs-char-text cs-char-text-${name} clear`} />

    </div>
  )
}

export default CharText
