import React, { ReactElement, useState } from 'react'

import "./CharInfo.scss"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  value?: string
  placeholder?: string
  onChange?: () => void
  save?: (text:string) => void
}


const CharInfo = ({ value = "", placeholder = "", ...props }: Props): ReactElement => {

  const { name } = props

  const [_value, setValue] = useState(value)

  return (
    <div className={props.className}>
      <input spellCheck="false" type="text"
        name={name}
        className={`cs cs-char-info ${name}`}
        placeholder={placeholder}
        value={_value}
        onChange={(e) => {
          const newText = e.currentTarget.value
          setValue(newText)
          if (props.onChange !== undefined) {
            props.onChange()
          }
          if (props.save !== undefined) {
            props.save(newText)
          }
        }}
      />
    </div>
  )
}

export default CharInfo
