import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faClock, faCog, faPause, faPlay, faQuestion, faUndo, faStop, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

import './ControlButton.scss'

export enum ButtonType {
  Play = "PLAY",
  Pause = "PAUSE",
  Stop = "STOP",
  Loading = "LOADING",
  Loop = "LOOP",
  Repeat = "REPEAT",
  Remove = "REMOVE"
}

const iconForButtonType = (bt: ButtonType): IconDefinition => {
  switch (bt) {
    case ButtonType.Play: return faPlay
    case ButtonType.Pause: return faPause
    case ButtonType.Stop: return faStop
    case ButtonType.Loading: return faCog
    case ButtonType.Loop: return faUndo
    case ButtonType.Repeat: return faClock
    case ButtonType.Remove: return faTrash
    default: return faQuestion
  }
}

const titleForButtonType = (bt: ButtonType): string => {
  switch (bt) {
    case ButtonType.Play: return "Play"
    case ButtonType.Pause: return "Pause"
    case ButtonType.Stop: return "Stop"
    case ButtonType.Loading: return "Buffering..."
    case ButtonType.Loop: return "Loop"
    case ButtonType.Repeat: return "Repeat"
    case ButtonType.Remove: return "Remove"
    default: return ""
  }
}

const classForButtonType = (bt: ButtonType): string => {
  switch (bt) {
    case ButtonType.Play: return "playback-button-play"
    case ButtonType.Pause: return "playback-button-pause"
    case ButtonType.Stop: return "playback-button-stop"
    case ButtonType.Loading: return "playback-button-loading"
    case ButtonType.Loop: return "playback-button-loop"
    case ButtonType.Repeat: return "playback-button-repeat"
    case ButtonType.Remove: return "library-remove"
    default: return ""
  }
}

const activeClassForButtonType = (bt: ButtonType): string => {
  switch (bt) {
    case ButtonType.Loop: return "loop-active"
    case ButtonType.Repeat: return "repeat-active"
    default: return ""
  }
}

const inactiveClassForButtonType = (bt: ButtonType): string => {
  switch (bt) {
    case ButtonType.Loop: return "loop-inactive"
    case ButtonType.Repeat: return "repeat-inactive"
    default: return ""
  }
}

const spinForButtonType = (bt: ButtonType): boolean => {
  switch (bt) {
    case ButtonType.Loading: return true
    default: return false
  }
}

const secondsToTimeString = (s: number): string => (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s

const secondsToHumanReadable = (s: number): string => {
  if (s < 60) {
    return s.toString() + "s"
  } else {
    if ((s % 60) > 0) {
      return (s / 60) + "m " + s + "s"
    } else {
      return (s / 60).toString() + "m"
    }
  }
}

const repeatAudioSecondsOptions: number[] = [
  10,
  15,
  30,
  60,
  60 * 2,
  60 * 3,
  60 * 5,
  60 * 15,
  60 * 30]

interface ControlButtonProps {
  type: ButtonType,
  disabled: boolean,
  isActive?: boolean,
  activeValue?: number,
  onClick?: (n?: any) => void
}

const ControlButton = ({ type, disabled = false, isActive = false, activeValue = -1, onClick = (n?: any) => { } }: ControlButtonProps) => {

  const classes = ["playback", "playback-button", classForButtonType(type)]

  if (disabled) {
    classes.push("disabled")
  }

  if (isActive) {
    classes.push(activeClassForButtonType(type))
  } else {
    classes.push(inactiveClassForButtonType(type))
  }

  if (type === ButtonType.Repeat) {

    const onSelect = (s: number) => onClick(s)

    return (
      <Dropdown className="d-inline" autoClose="inside">
        <Dropdown.Toggle disabled={disabled} className={classes.join(" ")}>

          <FontAwesomeIcon
            spin={spinForButtonType(type)}
            icon={iconForButtonType(type)} />

          <span className={`secondsLabel ${activeValue < 0 ? "d-none" : ""}`}>
            {secondsToTimeString(activeValue)}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item active={activeValue === -1} onClick={() => onSelect(-1)}>no repeat</Dropdown.Item>
          {repeatAudioSecondsOptions.map((seconds) => <Dropdown.Item
            key={seconds}
            active={activeValue === seconds}
            onClick={() => onSelect(seconds)}>Every {secondsToHumanReadable(seconds)}</Dropdown.Item>)}
        </Dropdown.Menu>

      </Dropdown>
    );
  }

  return (
    <button
      type="button"
      disabled={disabled}
      title={titleForButtonType(type)}
      className={classes.join(" ")}
      onClick={onClick}><FontAwesomeIcon
        spin={spinForButtonType(type)}
        icon={iconForButtonType(type)} /></button>
  );

}

export default ControlButton;
