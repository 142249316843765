import React from 'react';
import { CharacterSheet } from './cs/CharacterSheet'

import './CharacterSheets.scss'

function CharacterSheets() {

  return (
    <div className="cs cs-wrapper">
      <CharacterSheet />
    </div>
  );
}

export default CharacterSheets
