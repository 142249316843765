import React from 'react';
import { Container } from 'react-bootstrap';

function Home() {


  return (
    <Container fluid>

      <div className="container">
        <div className="jumbotron jumbotron-fluid">
          <h1 className="display-4 mt-4">RPG Party</h1>
          <p className="lead">A work-in-progress collection of tools to enhance your game experience</p>
          <p>Right now you can create an audio library to help your players feel more immersed in your next session as well as create and print a ICRPG Master Edition character sheet</p>
          <p>The previous desktop app for <a rel="noreferrer noopener" target="_blank" href="https://forums.runehammer.online/t/rpg-party-a-character-sheet-app/3829">character sheets</a> may return...</p>
        </div>

        <h2 className="display-6">Other tools you may find useful</h2>

        <ul>
          <li>
            Dungeon Map Doodler: <a href="https://dungeonmapdoodler.com">https://dungeonmapdoodler.com</a> quickly draw a dungeon
          </li>
          <li>
            Tabletop Audio: <a href="http://tabletopaudio.com">http://tabletopaudio.com</a> the original amazing audio resource for DM's
          </li>
          <li>
            Viking Death Squad (<a href="https://www.vikingdeathsquad.online">VDS</a>) character generator: <a href="https://vds.skippy.net">https://vds.skippy.net</a>
          </li>
          <li>
            AI avatar generator for NPC's and characters: <a href="https://artflow.ai">https://artflow.ai</a>
          </li>
          <li>
            2-Minute Tabletop tokens and more: <a href="https://2minutetabletop.com">https://2minutetabletop.com</a>
          </li>
          <li>
            Dungeondraft VTT map creator: <a href="https://dungeondraft.net">https://dungeondraft.net</a>
          </li>

        </ul>
      </div>


    </Container>
  );
}

export default Home;
